"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationIdFromJSON = LocationIdFromJSON;
exports.LocationIdFromJSONTyped = LocationIdFromJSONTyped;
exports.LocationIdToJSON = LocationIdToJSON;
var AirportIataCodeId_1 = require("./AirportIataCodeId");
var CityIataCodeId_1 = require("./CityIataCodeId");
var GooglePlaceId_1 = require("./GooglePlaceId");
var TrainlineGroupId_1 = require("./TrainlineGroupId");
var TrainlineStationId_1 = require("./TrainlineStationId");
function LocationIdFromJSON(json) {
    return LocationIdFromJSONTyped(json, false);
}
function LocationIdFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'airportIataCodeId':
            return __assign(__assign({}, (0, AirportIataCodeId_1.AirportIataCodeIdFromJSONTyped)(json, true)), { type: 'airportIataCodeId' });
        case 'cityIataCodeId':
            return __assign(__assign({}, (0, CityIataCodeId_1.CityIataCodeIdFromJSONTyped)(json, true)), { type: 'cityIataCodeId' });
        case 'googlePlaceId':
            return __assign(__assign({}, (0, GooglePlaceId_1.GooglePlaceIdFromJSONTyped)(json, true)), { type: 'googlePlaceId' });
        case 'trainlineGroupId':
            return __assign(__assign({}, (0, TrainlineGroupId_1.TrainlineGroupIdFromJSONTyped)(json, true)), { type: 'trainlineGroupId' });
        case 'trainlineStationId':
            return __assign(__assign({}, (0, TrainlineStationId_1.TrainlineStationIdFromJSONTyped)(json, true)), { type: 'trainlineStationId' });
        default:
            throw new Error("No variant of LocationId exists with 'type=".concat(json['type'], "'"));
    }
}
function LocationIdToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'airportIataCodeId':
            return (0, AirportIataCodeId_1.AirportIataCodeIdToJSON)(value);
        case 'cityIataCodeId':
            return (0, CityIataCodeId_1.CityIataCodeIdToJSON)(value);
        case 'googlePlaceId':
            return (0, GooglePlaceId_1.GooglePlaceIdToJSON)(value);
        case 'trainlineGroupId':
            return (0, TrainlineGroupId_1.TrainlineGroupIdToJSON)(value);
        case 'trainlineStationId':
            return (0, TrainlineStationId_1.TrainlineStationIdToJSON)(value);
        default:
            throw new Error("No variant of LocationId exists with 'type=".concat(value['type'], "'"));
    }
}
