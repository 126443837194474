"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellerMeal = void 0;
exports.TravellerMealFromJSON = TravellerMealFromJSON;
exports.TravellerMealFromJSONTyped = TravellerMealFromJSONTyped;
exports.TravellerMealToJSON = TravellerMealToJSON;
/**
 * A meal preference that a traveller can have
 * @export
 */
exports.TravellerMeal = {
    BabyMeal: 'babyMeal',
    BlandMeal: 'blandMeal',
    AsianVegetarianMeal: 'asianVegetarianMeal',
    DiabeticMeal: 'diabeticMeal',
    GlutenFreeMeal: 'glutenFreeMeal',
    HinduMeal: 'hinduMeal',
    KosherMeal: 'kosherMeal',
    HalalMeal: 'halalMeal',
    VeganMeal: 'veganMeal',
    VegetarianLactoOvoMeal: 'vegetarianLactoOvoMeal',
    LowFatMeal: 'lowFatMeal',
    LowSaltMeal: 'lowSaltMeal',
    LowCalorieMeal: 'lowCalorieMeal',
    LactoseFreeMeal: 'lactoseFreeMeal',
    ChildMeal: 'childMeal',
    VegetarianMeal: 'vegetarianMeal'
};
function TravellerMealFromJSON(json) {
    return TravellerMealFromJSONTyped(json, false);
}
function TravellerMealFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellerMealToJSON(value) {
    return value;
}
